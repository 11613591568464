:root {
  --main-bg-color: #021b2c;
  --secondary-color: #fcb040;
  --light-main-bg-color: #021b2ce0;
}

body {
  overflow-x: hidden !important;
  font-family: "Lato", sans-serif;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--main-bg-color);
  background-color: var(--main-bg-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: white;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}

header {
  width: 100vw !important;
  background: rgba(0, 0, 0, 0.49);
  height: 100vh;
}

.navbar {
  background: rgba(0, 0, 0, 0.546);
  border-bottom: 3px solid var(--secondary-color);
  z-index: 100;
}

.featured-properties-container {
  background-color: var(--main-bg-color);
  border-top: 3rem solid var(--secondary-color);
}

.home-slide {
  background: linear-gradient(var(--light-main-bg-color), rgba(0, 0, 0, 0.717) 100%), url("../../../public/images/header/header_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white !important;
  border-top: 3rem solid var(--secondary-color);
}

.property-wrap,
.property-summary {
  background-color: var(--main-bg-color);
  border-top: 1rem solid var(--secondary-color);
}

.list-container {
  background: linear-gradient(var(--light-main-bg-color), rgba(0, 0, 0, 0.717) 100%), url("../../../public/images/header/header_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white !important;
  border-top: 1rem solid var(--secondary-color);
}

.property-summary {
  border-top: 1px solid rgba(255, 255, 255, 0.411);
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #021b2c;
  padding: 5px;
  border-radius: 50%;
}

.carousel-indicators {
  color: var(--secondary-color) !important;
  padding: 20px;
}

.contact-form-container {
  background: linear-gradient(to right, var(--main-bg-color), #3d1674);
  border-top: 3rem solid var(--secondary-color);
}





